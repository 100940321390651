<template>
  <b-card class="earnings-card">
    <b-row>
      <b-col cols="6">
        <b-card-title class="mb-1"> Factures soldées </b-card-title>

        <b-card-text>
          <div class="font-small-2 pt-2">Total des tâches</div>
          <b-skeleton v-if="loading"></b-skeleton>
          <h4>{{ count_invoices_paid }}</h4>
          <span
            ><h4 class="text-info">
              <b-skeleton v-if="loading"></b-skeleton>
              <span v-else>
                <small>Soit </small
                ><strong>{{ amount_invoices_paid }}</strong> €</span
              >
            </h4></span
          >
        </b-card-text>
      </b-col>
      <b-col cols="6">
        <!-- chart -->
        <vue-apex-charts
          height="120"
          :options="chartOptions"
          :series="[finishedPer]"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BCardTitle, BCardText } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

const $earningsStrokeColor2 = "#28a745d4";
const $earningsStrokeColor3 = "#28a74559";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
  },

  data() {
    return {
      chartOptions: {
        chart: {
          type: "radialBar",
        },
        dataLabels: {
          show: false,
        },

        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                color: $themeColors.warning,
                fontSize: "14px",
              },
            },
          },
          dataLabels: {
            showOn: "always",

            name: {
              offsetY: -10,
              show: false,
              color: "#888",
              fontSize: "13px",
            },
            value: {
              color: "#111",
              fontSize: "30px",
              show: true,
            },
          },
        },

        stroke: { width: 0 },
        colors: [$themeColors.warning],
        grid: {
          padding: {
            right: -50,
            bottom: -20,
            left: -50,
          },
        },
      },
    };
  },

  props: [
    "count_invoices",
    "count_invoices_paid",
    "count_invoices_unpaid",
    "amount_invoices_paid",
    "loading",
  ],

  computed: {
    finishedPer() {
      var myPer = this.count_invoices_paid
        ? (100 * this.count_invoices_paid) /
          (this.count_invoices_paid +
            this.count_invoices_unpaid +
            this.count_invoices)
        : 0;
      return myPer.toFixed(1);
    },
  },
};
</script>
