<template>
  <section>
    <!-- <h3>Statistiques </h3> -->
    <!-- <b-row>
      <b-col cols="12" md="3">
        <label for="example-datepicker">Filtrer par date : De </label>
        <b-form-datepicker
          id="example-datepicker"
          class="mb-2"
          v-model="periodeFilter.from"
          placeholder="De..."
        ></b-form-datepicker>
      </b-col>
      <b-col cols="12" md="3">
        <label for="example-datepicker">À</label>
        <b-form-datepicker
          id="example-datepicker"
          class="mb-2"
          placeholder="..."
          v-model="periodeFilter.to"
        ></b-form-datepicker>
      </b-col>
    </b-row> -->
    <b-row>
      <!-- <b-col md="4">
        <billable-tasks-chart
          :loading="statsLoading"
          :amount_invoices="statsArray.total_count_invoices"
          :count_invoices="statsArray.count_invoices"
          :count_invoices_paid="statsArray.count_invoices_paid"
          :count_invoices_unpaid="statsArray.count_invoices_unpaid"
        />
      </b-col> -->
      <!-- <b-col md="4">
        <billable-tasks-billed-chart
          :loading="statsLoading"
          :amount_invoices_paid="statsArray.total_count_invoices_paid"
          :count_invoices="statsArray.count_invoices"
          :count_invoices_paid="statsArray.count_invoices_paid"
          :count_invoices_unpaid="statsArray.count_invoices_unpaid"
        />
      </b-col>
      <b-col md="4">
        <billable-tasks-not-billed-chart
          :loading="statsLoading"
          :count_invoices="statsArray.count_invoices"
          :count_invoices_paid="statsArray.count_invoices_paid"
          :count_invoices_unpaid="statsArray.count_invoices_unpaid"
          :amount_invoices_unpaid="statsArray.total_count_invoices_unpaid"
        />
      </b-col> -->
    </b-row>
    <b-card no-body class="">
      <template #header>
        <h2>Collaborateurs</h2>
      </template>
      <div class="pl-2 pr-2 pb-2">
        <b-form>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group>
                <label for="">Filtrer par collaborateur :</label>
                <multiselect
                  v-model="collaboratorMultiSelect"
                  placeholder="Collaborateurs..."
                  :options="masters"
                  :custom-label="userName"
                  :value="collaboratorMultiSelect"
                  selectLabel=""
                  deselectLabel=""
                  :showNoResults="false"
                >
                  span(slot="noResult"). Oops! No elements found. Consider
                  changing the search query. pre.language-json code.
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <p>Legende :</p>
        <div class="d-flex align-center">
          <span class="ml-1">
            <b-avatar icon="." size="18px" variant="warning"></b-avatar>
            Tâches en cours
          </span>
          <span class="ml-1">
            <b-avatar icon="." size="18px" variant="success"></b-avatar>
            Tâches achevées
          </span>
          <span class="ml-1">
            <b-avatar icon="." size="18px" variant="danger"></b-avatar>
            Tâches en retard
          </span>
        </div>
      </div>

      <div class="">
        <b-table
          ref="refInvoiceListTable"
          :items="enterpriseStats"
          responsive
          :busy="isLoading"
          :fields="userTableColumns"
          show-empty
          empty-text="Pas de collaborateur disponible"
          class="position-relative"
        >
          <template #table-busy>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
          </template>
          <template #head(invoiceStatus)>
            <feather-icon icon="TrendingUpIcon" class="mx-auto" />
          </template>

          <template #cell(name)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  badge
                  :badge-variant="data.item.online ? 'success' : 'danger'"
                  badge-offset="-2px"
                  class="badge-minimal"
                  size="32"
                  :src="data.item.picture"
                />
              </template>
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item.firstname + " " + data.item.lastname }}
              </span>
              <small class="text-muted">{{ data.item.email }}</small>
            </b-media>
          </template>

          <template #cell(count_ongoing)="data">
            <div class="d-flex justify-content-between">
              <b-badge
                @click="toggleTableDetails(data, 'ongoing_tasks')"
                class="stat-badge"
                pill
                variant="warning"
                >{{ data.item.count_ongoing }}</b-badge
              >
              <b-badge
                @click="toggleTableDetails(data, 'finished_tasks')"
                class="stat-badge"
                pill
                variant="success"
                >{{ data.item.count_finished }}</b-badge
              >
              <b-badge
                @click="toggleTableDetails(data, 'delayed_tasks')"
                class="stat-badge"
                pill
                variant="danger"
                >{{ data.item.count_delayed }}</b-badge
              >
            </div>
          </template>

          <template #cell(count_billable)="data">
            Tâche(s) :
            <b-badge
              @click="toggleTableDetails(data, 'billable-tasks')"
              class="stat-badge opacity-50 mb-1"
              >{{ data.item.count_billable }}</b-badge
            >
            <br />
            <!-- <b-alert class="pl-1" show>
              <p>
                Revenu total :
                <strong>{{ data.item.total_count_billable }}€ </strong>
              </p></b-alert
            > -->
          </template>

          <template #cell(count_billed)="data">
            Tâche(s) :
            <b-badge
              @click="toggleTableDetails(data, 'billable-tasks-billed')"
              class="stat-badge opacity-50 mb-1"
              >{{ data.item.count_billed }}</b-badge
            >
            <br />
            <b-alert class="pl-1" show>
              <p>
                Revenu total :
                <strong>{{ data.item.total_count_billed }}€ </strong>
              </p></b-alert
            >
          </template>

          <template #cell(count_not_billed)="data">
            Tâche(s) :
            <b-badge
              @click="toggleTableDetails(data, 'billable-tasks-not-billed')"
              class="stat-badge opacity-50 mb-1"
              >{{ data.item.count_not_billed }}</b-badge
            >
            <br />
            <!-- <b-alert class="pl-1" show>
              <p>
                Revenu total :
                <strong>{{ data.item.total_count_not_billed }}€ </strong>
              </p></b-alert
            > -->
          </template>

          <template class="stat-badge" #cell(count_total)="data">
            <b-badge variant="primary">{{ data.item.count_total }}</b-badge>
          </template>

          <template #row-details="row">
            <b-card>
              <b-table
                :key="table_key"
                show-empty
                empty-text="Aucune donnée"
                :fields="row_details_fields"
                :busy="isBusy"
                :items="row_details_items[row.item.id]"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Chargement...</strong>
                  </div>
                </template>
                <template #cell(is_finished)="data" class="task-respo">
                  <b-form-checkbox
                    :disabled="_getTaskCheckBoxState(userInfo, data.item)"
                    v-model="data.item[getKey(data.item)]"
                    :indeterminate="
                      ((!data.item.is_closed && data.item.is_finished) ||
                        (data.item.supervisor_id &&
                          data.item.is_finished &&
                          !data.item.is_closed)) == 1
                        ? true
                        : false
                    "
                    class="custom-control-primary"
                    :value="1"
                    @change="_taskChecked(data.item)"
                  >
                  </b-form-checkbox>
                </template>
                <template #cell(infos)="data">
                  <div>
                    <b>Responsable</b> {{ getMaster(data.item) }} <br />
                    <b>Superviseur</b>: {{ getSupervisor(data.item) }}
                  </div>
                </template>
                <template #cell(activity)="data">
                  <b-btn
                    :disabled="
                      userInfo.id != data.item.user_id &&
                      userInfo.id != data.item.supervisor_id
                    "
                    @click="
                      $router.push('/task/' + data.item.id + '/changelogs')
                    "
                  >
                    <feather-icon icon="ActivityIcon"></feather-icon>
                  </b-btn>
                </template>
              </b-table>
              <br />

              <b-button size="sm" @click="row.toggleDetails">Fermer </b-button>
            </b-card>
          </template>
        </b-table>
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="employees_meta.total"
            :per-page="employees_meta.per_page"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-1 ml-1"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </b-card>
  </section>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import Multiselect from "vue-multiselect";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import axios from "@/libs/axios";
import BillableTasksNotBilledChart from "@/views/charts/BillableTasksNotBilledChart.vue";
import BillableTasksBilledChart from "@/views/charts/BillableTasksBilledChart.vue";
import BillableTasksChart from "@/views/charts/BillableTasksChart.vue";
export default {
  components: {
    BCard,
    Multiselect,
    BCardText,
    BLink,
    FeatherIcon,
    ToastificationContent,
    BillableTasksNotBilledChart,
    BillableTasksBilledChart,
    BillableTasksChart,
  },
  data() {
    const now = new Date();

    const from_date = new Date(
      now.getFullYear(),
      now.getMonth() - 1,
      now.getDate()
    );
    const to_date = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    return {
      statsArray: [],
      statsLoading: false,
      isLoading: true,
      collaboratorMultiSelect: null,
      selected_user_id: null,
      loader: false,
      errorModal: "",
      errors: "",
      loading: false,
      progress: false,

      currentPage: 1,

      periodeFilter: {
        from: new Date(from_date).toISOString().split("T")[0],
        to: new Date(to_date).toISOString().split("T")[0],
      },

      mainProps: {
        blank: true,
        width: 300,
        height: 75,
        class: "m2",
      },

      filterData: {
        user_id: null,
        table_size: 10,
        page: 1,
      },

      userTableColumns: [
        {
          key: "name",
          sortable: true,
          label: "Noms et prénoms",
          thStyle: { width: "20%" },
        },
        {
          key: "count_ongoing",
          label: "Tâches",
          sortable: true,
          thStyle: { width: "10%" },
        },
        { key: "count_billable", label: "Facturables", sortable: true },
        { key: "count_billed", label: "Facturées", sortable: true },
        {
          key: "count_not_billed",
          label: "Non facturées",
          sortable: true,
        },

        { key: "count_total", label: "Total", sortable: true },
      ],

      table_key: 0,
      isBusy: false,
      row_details_fields: [
        { key: "is_finished", label: "Achevé" },
        { key: "name", label: "Nom de la tâche" },
        { key: "start_date", label: "Date de début" },
        { key: "end_date", label: "Date de fin" },
        { key: "infos", label: "Infos" },
        { key: "activity", label: " Voir Les Activités" },
      ],
      row_details_items: [],
    };
  },
  computed: {
    masters() {
      return this.employees;
    },

    ...mapState("enterprise", [
      "enterpriseStats",
      "employees_meta",
      "employees",
      "countStats",
    ]),
    ...mapState("auth", ["userInfo"]),
  },

  methods: {
    userName({ firstname, lastname }) {
      return firstname + " " + lastname;
    },

    getMaster(task) {
      if (task.master?.id == this.userInfo.id) {
        return "Moi";
      } else {
        return task.master?.firstname || "-";
      }
    },
    getSupervisor(task) {
      if (task.supervisor?.id == this.userInfo.id) {
        return "Moi";
      } else {
        return task.supervisor?.firstname || "-";
      }
    },

    getKey(task) {
      if (
        task.supervisor_id == this.userInfo.id ||
        (task.is_finished == 1 && this.userInfo.id == 19) ||
        (task.is_finished == 1 && this.userInfo.id == 5)
      )
        return "is_closed";
      if (task.supervisor_id == null) return "is_closed";
      if (task.user_id == this.userInfo.id) return "is_finished";
    },

    _taskChecked(task) {
      this.taskChecked({ task: task, userInfo: this.userInfo }).then((r) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Opération éffectuée.",
            icon: "EditIcon",
            variant: "success",
          },
        });
      });
    },

    ...mapActions("enterprise", [
      "getEnterpriseStats",
      "getEmployees",
      "getCountStats",
      "myGlobalFilter",
    ]),
    ...mapActions("tasks", ["taskChecked"]),

    toggleTableDetails(row, route) {
      this.selected_user_id = row.item.id;
      this.isBusy = true;
      this.fetchRowDetailsItems(row, route).then((r) => {
        this.isBusy = false;

        this.row_details_items[row.item.id] = r.data.data;
        this.table_key++;
      });

      row.toggleDetails();
    },

    fetchRowDetailsItems({ item }, route) {
      return new Promise((resolve, reject) => {
        axios.get("user/" + item.id + "/" + route).then((r) => {
          resolve(r);
        });
      });
    },

    //----End Notify -----

    _getTaskCheckBoxState(user, task) {
      if (task.is_finished == 1 && user.id == 5) return false;
      if (task.is_finished == 1 && user.id == 19) return false;
      if (task.user_id != user.id && task.supervisor_id != user.id) return true;
      if (task.user_id == user.id && task.supervisor_id == null) return false;
      if (task.supervisor_id == user.id && task.user_id == user.id)
        return false;
      if (task.supervisor_id == user.id && task.is_finished == 0) return true;
      if (task.user_id == user.id && task.is_closed == 1) return true;
    },
  },

  watch: {
    collaboratorMultiSelect(val) {
      if (val) {
        this.filterData.user_id = val.id;
      } else {
        this.filterData.user_id = null;
      }
    },

    periodeFilter: {
      deep: true,
      handler() {
        this.statsLoading = true;
        return this.getCountStats(this.periodeFilter).then((res) => {
          this.statsLoading = false;
          this.statsArray = res.data;
        });
      },
    },

    filterData: {
      deep: true,
      handler() {
        this.isLoading = true;
        this.filterData.table_size = this.thisTableSize;
        return this.getEnterpriseStats(this.filterData).then(() => {
          this.isLoading = false;
        });
      },
    },

    currentPage(val) {
      this.isLoading = true;
      this.filterData.page = val;
      this.getEnterpriseStats(this.filterData).then(() => {
        this.isLoading = false;
      });
    },
  },

  mounted() {
    this.getEmployees().then(() => {
      this.getCountStats(this.periodeFilter).then((res) => {
        this.statsArray = res.data;
      });
    });
    this.getEnterpriseStats(this.filterData).then(() => {
      this.isLoading = false;
    });
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.stat-badge {
  cursor: pointer;
}
</style>
